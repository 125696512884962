import React, { Component } from "react";
import "../../components/ProductListItem/ProductListItem.css";

class COAListItem extends Component {
  render() {
    const { img, title, id } = this.props;


    return (
      <li className="product-list-item" id={id}>
        <a href={img} target="_blank">
            <img
                src={`${img}`}
                alt={title}
                className="product-list-item-img" />
            </a>    
        <h2 className="product-list-item-title">{`${title}`}</h2>
      </li>
    );
  }
}

export default COAListItem;
