import React from 'react';

import './Square.css';

const appId = 'sq0idp-xAZPemCdjpdmKwoHw6rC6w';
const locationId = '99VPMY8AEH5XG';

class SquarePaymentForm extends React.Component {
  constructor(props) {
    super(props);
  
    this.state = { card: {}}
  }

  async componentDidMount() {
    // initialize the payments API from `square`
    const payments = window.Square.payments(appId, locationId);
    const card = await this.initializeCard(payments);

    this.setState({ card });
  }

  initializeCard = async payments => {
    const card = await payments.card();
    await card.attach('#card-container');

    return card;
  }

  tokenize = async () => {
    const { isEmptyInput, notifyFormEmpty, setIsLoading } = this.props;

    if (isEmptyInput) {
      notifyFormEmpty();

      return;
    }

    setIsLoading(true);

    const { card } = this.state;
    const tokenResult = await card.tokenize();

    if (tokenResult.status === 'OK') {
      await this.props.onSubmit(tokenResult.token);

      setIsLoading(false);

      return;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
    
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${tokenResult.errors}`;
      }
    
      console.error('tokenize failed', errorMessage);
      if (setIsLoading(false));
    }
  }
  
    render() {
      const { total } = this.props;
      const buttonText = `Pay $${total.toFixed(2)}`;

        return (
            <form id="payment-form">
                <div id="card-container"></div>     
                <button id="card-button" type="button" className='sq-creditcard' onClick={this.tokenize}>{buttonText}</button>
            </form>
        )
    }
}

export default SquarePaymentForm;
