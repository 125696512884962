import React from 'react'
import COAListItem from './COAListItem';
import Grid from '@material-ui/core/Grid';
import SpinnerMui from '../../components/SpinnerMUI/SpinnerMui';


const Items = ({ items, loading }) => {
    console.log('items: ', items);
    let coas = items.map((e) => (
        <Grid item xs={12} md={4} lg={4} key={e._id}>
            <COAListItem 
                img={e.productImage}
                title={e.title}
                id={e._id}/>
        </Grid>
    ))
    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SpinnerMui />
        </div>

    }
    return (
        <Grid container spacing={3}>
            {coas}
        </Grid>
    )
}

export default Items;
