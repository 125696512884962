import React, { Component } from "react";
import { connect } from "react-redux";
import CartItem from "../CartItem/CartItem";
import ShippingFee from "./ShippingFee";
import "./Cart.css";
import "react-toastify/dist/ReactToastify.css";
import EmptyCartDesktop from "./EmptyCartDesktop";
import EmptyCartMobile from "./EmptyCartMobile";
import FullCartMobile from "./FullCartMobile";
import FullCartDesktop from "./FullCartDesktop";
import { removeAllItems } from "../../store/actions/itemActions";
import { toast } from "react-toastify";

class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDesktop: false,
      isOpenModal: false,
      isLoading: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updatePredicate = this.updatePredicate.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
  }

  openModal() {
    this.setState({ isOpenModal: true });
  }

  closeModal() {
    this.setState({ isOpenModal: false });
  }

  setIsLoading = (isLoading) => {
    this.setState({ isLoading });
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 975 });
  }

  // toast functions
  notifySuccess = () =>
    toast(`Thanks for shopping! Your receipt will be e-mailed shortly.`, {
      className: "successToast",
    });

  notifyFailure = () =>
    toast(
      `Something went wrong during the transaction. If this continues, please give us a call!`,
      { className: "failureToast", progressClassName: "failBackground" }
    );

  notifyFormEmpty = () => (
      toast(`Please finish providing your contact or shipping Info`, { className: "failureToast", progressClassName: "failBackground" })
  )

  getOrderString() {
    let orderItems = this.props.checkoutItems.map((item) => {
      return `${item.title} x${item.qty}`;
    });
    return orderItems.join(", ");
  }

  getOrderPrice() {
    let subTotal = 0;
    this.props.checkoutItems.forEach((item) => {
      subTotal += item.qty * item.price;
    });

    const totalAfterTax = subTotal * 1.07;

    return totalAfterTax.toFixed(2);
  }

  getOrderData() {
    let dataArray = this.props.checkoutItems.map((e) => {
      return { id: e.id, qty: e.qty };
    });
    return dataArray;
  }

  getOrderPricePlusShipping() {
    const total = this.getOrderPrice();
    if (total >= 60) {
      return Number(total)
    } else {
      return (Number(total) + 9.00);
    }
  }

  handleResponse(status) {
    // this used to be "success"
    // showing as 200 for successful in local
    // and "success" string for successful in prod
    if (status === 200) {
      this.props.removeAllItems();
      this.setState({ isOpenModal: false });
      this.notifySuccess();
    } else {
      this.notifyFailure();
    }
  }

  renderCartItems() {
    return this.props.checkoutItems.map((item, index) => (
      <CartItem
        title={item.title}
        price={item.price}
        img={item.img}
        index={index}
        id={item.id}
        key={index}
        qty={item.qty}
      />
    ));
  }

  renderCheckoutItems() {
    return this.props.checkoutItems.map((item, index) => (
      <li key={index} className="cart-item-li">
        {item.title} x{item.qty}
      </li>
    ));
  }

  renderShippingFee() {
    const isShippingWaved = this.getOrderPrice() >= 60;

    const taxAmount = (this.getOrderPrice() - (this.getOrderPrice() / 1.07)).toFixed(2);

    return <ShippingFee wavedShipping={isShippingWaved} taxAmount={taxAmount} />;
  }

  render() {
    const { isDesktop } = this.state;
    const cartItems = this.renderCartItems();
    const checkoutItems = this.renderCheckoutItems();

    const total = this.getOrderPricePlusShipping();
    const amount = this.getOrderPricePlusShipping();

    const shippingFee = this.renderShippingFee();
    const orderData = this.getOrderData();
    const orderName = this.getOrderString();

    if (isDesktop) {
      if (this.props.checkoutItems.length < 1) {
        return <EmptyCartDesktop />;
      } else {
        return (
          <FullCartDesktop
            cartItems={cartItems}
            checkoutItems={checkoutItems}
            shippingFeeComponent={shippingFee}
            total={total}
            amount={amount}
            data={orderData}
            name={orderName}
            handleResponse={this.handleResponse}
            openModal={this.openModal}
            closeModal={this.closeModal}
            isOpenModal={this.state.isOpenModal}
            notifyFormEmpty={this.notifyFormEmpty}
            setIsLoading={this.setIsLoading}
            isLoading={this.state.isLoading}
          />
        );
      }
    } else {
      if (this.props.checkoutItems.length < 1) {
        return <EmptyCartMobile />;
      } else {
        return (
          <FullCartMobile
            cartItems={cartItems}
            checkoutItems={checkoutItems}
            shippingFeeComponent={shippingFee}
            total={total}
            amount={amount}
            data={orderData}
            name={orderName}
            handleResponse={this.handleResponse}
            openModal={this.openModal}
            closeModal={this.closeModal}
            isOpenModal={this.state.isOpenModal}
            notifyFormEmpty={this.notifyFormEmpty}
            setIsLoading={this.setIsLoading}
            isLoading={this.state.isLoading}
          />
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  checkoutItems: state.checkoutItems.items,
});

export default connect(mapStateToProps, { removeAllItems })(Cart);
