import "./Square.css";

import React, { Component } from "react";
import axios from "axios";

import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import ModalMUI from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";

import SquarePaymentForm from "./SquarePaymentForm";
import SpinnerMUI from '../SpinnerMUI/SpinnerMui';

const locationId = 'BHXWX6S2G8A4F';

export default class SquareModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      state: "",
      zip: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  get isEmptyInput() {
    const { name, email, streetAddress1, city, state, zip } = this.state;

    if (!name || !email || !streetAddress1 || !city || !state || !zip) {
      return true;
    }

    return false;
  }

  submitPaymentWithFormDataAndToken = async token => {
      const { data, orderString } = this.props; 
      const { name, email, streetAddress1, streetAddress2, city, state, zip } = this.state;
      const checkoutItems = { data, orderString };

      const payload = {
        // square payment params
        locationId,
        sourceId: token,
        // user info params
        name, 
        email, 
        streetAddress1,
        streetAddress2,
        city,
        state,
        zip,
        token,
        checkoutItems
      };

      const response = await axios.post('/charge', payload);
      const { status } = response;

      this.props.handleResponse(status);
  }

  renderContent = () => {
    const { total, isLoading } = this.props;
    const { name, email, streetAddress1, streetAddress2, city, state, zip } = this.state;

    if (isLoading) {
      return <SpinnerMUI />
    }

    return (
      <>
            <Grid container spacing={2} className="sq-fieldset">
              <Grid item xs={12} md={6}>
                <div className="flex-column sqm-customer-1">
                  <h3 className="section-h3">Contact Info</h3>
                  <div>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      className="sqm-input"
                      onChange={this.handleChange}
                      value={name}
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      required
                      className="sqm-input"
                      onChange={this.handleChange}
                      value={email}
                    />
                  </div>
                </div>

                <div className="flex-column sqm-customer-2">
                  <h3 className="section-h3"> Shipping Address</h3>

                  <input
                    className="sqm-input"
                    type="text"
                    id="streetAddress1"
                    name="streetAddress1"
                    placeholder="Address Line 1"
                    onChange={this.handleChange}
                    value={streetAddress1}
                  />

                  <input
                    className="sqm-input"
                    type="text"
                    id="streetAddress2"
                    name="streetAddress2"
                    placeholder="Address Line 2"
                    onChange={this.handleChange}
                    value={streetAddress2}
                  />

                  <input
                    className="sqm-input"
                    type="text"
                    id="city"
                    name="city"
                    placeholder="City"
                    onChange={this.handleChange}
                    value={city}
                  />

                  <input
                    className="sqm-input"
                    type="text"
                    id="state"
                    name="state"
                    placeholder="2 Digit State"
                    maxlength={2}
                    onChange={this.handleChange}
                    value={state}
                  />

                  <input
                    className="sqm-input"
                    type="text"
                    id="zip"
                    name="zip"
                    placeholder="Zip"
                    maxlength={5}
                    onChange={this.handleChange}
                    value={zip}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <h3 className="section-h3">Payment Info</h3>
                <div className="paymentInfoAndDisclosure">
                  <div className="squareFormWrapper">
                    <SquarePaymentForm 
                      notifyFormEmpty={this.props.notifyFormEmpty}
                      isEmptyInput={this.isEmptyInput}
                      total={total} 
                      setIsLoading={this.props.setIsLoading}
                      onSubmit={this.submitPaymentWithFormDataAndToken} />
                  </div>

                  
                <img src="/square.jpg" className="square-img"></img>
                <p>Use our PCI complient payment form to safely pay for your products!</p>
                <p>
                    By confirming payment, you agree to letting us store your data to create a
                    customer profile. (Credit card info will not be saved)
                </p>

                </div>
              </Grid>
            </Grid>
      </>
    )
  }

  render() {
    return (
      <ModalMUI
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={this.props.isOpenModal}
        onClose={this.props.closeModal}
        className="modal"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}>
        <Fade in={this.props.isOpenModal}>
          <div className="paper paper-s">
            { this.renderContent() }
          </div>
        </Fade>
      </ModalMUI>
    );
  }
}

