import React, { useState, useEffect } from "react";
import '../Products/Products.css';
import Title from '../../components/Title/Title';
import axios from 'axios';
import Items from './Items';
import Pagination from '../Products/Pagination';
import './COAPage.css'


const COA = () => {
  // create state values using useState
  const [items, setItems] = useState([]);
  const [searchBarValue, setSearchBarValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);

  // call useEffect to get json from api - aka componentDidMount
  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const res = await axios.get('/api/coa');
      setItems(res.data);
      setLoading(false);
    }
    fetchPosts();
  }, [])

  let filteredCOA = items.filter(coa => {
      return coa.title.toLowerCase().indexOf(searchBarValue.toLowerCase()) !== -1;
  });

  function searchItems(e) {
    setSearchBarValue(e.target.value);
    setCurrentPage(1)
  }

  // get current posts
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCOA.slice(indexOfFirstItem, indexOfLastItem);

  // change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  }

  const renderItems = () => {
      if (currentItems.length > 0) {
          return <Items items={currentItems} loading={loading} />
      }

      return <div className="nullMessage">Nothing here yet!</div>
  }

  return (
    <div className="products-page">
      <Title text={'Certificates of Analysis'} />
      <input type="text" value={searchBarValue}
        onChange={e => searchItems(e)}
        placeholder="Search COAs"
        className="filterInput"
      ></input>
      {renderItems()}
      <Pagination itemsPerPage={itemsPerPage} totalItems={filteredCOA.length}
        paginate={paginate} currentPage={currentPage}
      />
    </div>
  );

}


export default COA;