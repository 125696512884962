import React from 'react';

const ShippingFee = (props) => {
    let feeText;
    let classText;
    
    (props.wavedShipping ? feeText = 'Shipping Fee: $9.00' : feeText = '+ Shipping Fee: $9.00');
    (props.wavedShipping ? classText = 'cart-item-li free-shipping' : classText = 'cart-item-li')

    return (
        <React.Fragment>
            <li className="cart-item-li">+ Tax: ${props.taxAmount}</li>
            <li className={classText}>{feeText}</li>
        </React.Fragment>
    )
}

export default ShippingFee;